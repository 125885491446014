<template>
  <v-container id="list-training">
    <h2 class="training__title">{{ $t('admin.training.trainingList.title.listOfTraining') }}</h2>

    <TrainingListTable
      backgroundColor="var(--v-adminBackground-base)"
      :search-bar="true"
      :can-add="canCreate"
      :can-edit="canUpdate"
      :can-delete="canDelete"
    />
  </v-container>
</template>

<script>
import TrainingListTable from '@/views/admin/trainings/training-list/training-list-table/TrainingListTable.vue';

export default {
  name: 'ListofTraining',
  components: {
    TrainingListTable,
  },
  computed: {
    canUpdate() {
      return true;
    },
    canCreate() {
      return true;
    },
    canDelete() {
      return true;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

@include admin-layout();

#list-training {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.training {
  &__title {
    margin-bottom: 10px;
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
