var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-items"},[_c('div',{staticClass:"content-items__actions mt-4"},[_c('v-btn',{staticClass:"actions__create",attrs:{"data-test-id":"add","large":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.dialogUserList = true}}},[_vm._v(" "+_vm._s(_vm.$t('trainings.linkATrainerAccountToTheTraining'))+" ")])],1),_c('base-list',{staticClass:"mt-4",attrs:{"className":"img-first","headers":_vm.headers,"search":_vm.search,"items":_vm.contentItems,"loading":_vm.itemsAreLoading,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult'),"showFooter":false},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"container-img"},[(item.avatar)?_c('img',{staticClass:"container-img__img",attrs:{"src":item.avatar,"alt":"content-img"}}):_c('p',{staticClass:"container-img__placeholder"},[_vm._v(" "+_vm._s(_vm.$t('admin.users.noAvatar'))+" ")])])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"truncate ml-2"},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"globals.edit"},on:{"click":function($event){return _vm.deleteContentItem(item)}}},[_vm._v("mdi-link-off ")])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"content-class":"v-dialog--overlay","width":"auto","id":"dialog-user-list"},model:{value:(_vm.dialogUserList),callback:function ($$v) {_vm.dialogUserList=$$v},expression:"dialogUserList"}},[_c('v-container',{staticClass:"white",staticStyle:{"margin-top":"50px"},attrs:{"id":"users"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',{staticClass:"user-list__title"},[_vm._v("List trainer")]),_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"title":"close","color":"red"},on:{"click":function($event){_vm.dialogUserList = false}}},[_vm._v("mdi-close ")])],1),_c('div',{staticClass:"user-list__actions"},[_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{staticClass:"mt-4",attrs:{"className":"img-first","backgroundColor":"var(--v-adminBackground-base)","headerProps":{ sortIcon: null },"headers":_vm.headersUserList,"search":_vm.search,"items":_vm.eventUsers,"itemPerPage":5,"footerProps":{
          itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
          itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
          pageText: _vm.$t('globals.datatable.of'),
        },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"container-img"},[(item.avatar)?_c('img',{staticClass:"container-img__img",attrs:{"src":item.avatar}}):_c('p',[_vm._v(_vm._s(_vm.$t('admin.users.noAvatar')))])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.activate",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"users-actions"},[_c('v-btn',{attrs:{"icon":"","color":"secondary"}},[(item.ticket.active)?_c('v-icon',{attrs:{"size":"40","color":"primary","title":_vm.$t('admin.users.list.deactivate')}},[_vm._v(" mdi-toggle-switch ")]):_c('v-icon',{attrs:{"size":"40","title":_vm.$t('admin.users.list.activate')}},[_vm._v(" mdi-toggle-switch-off ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(!_vm.isAdded(item))?_c('v-icon',{attrs:{"title":"Add user"},on:{"click":function($event){return _vm.addUser(item)}}},[_vm._v("mdi-account-plus ")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }