<template>
  <v-container class="edit-partner">
    <v-form v-model="valid" :disabled="!canUpdate" @submit="save" @submit.prevent>
      <v-tabs v-if="isEditing" v-model="tab">
        <v-tab href="#training" @click="handleActiveTab('training')">
          {{ $t('admin.training.trainingList.popup.tab.training') }}
        </v-tab>

        <v-tab href="#trainer" @click="handleActiveTab('trainer')">
          {{ $t('trainings.Trainers') }}
        </v-tab>

        <v-tab href="#certification" @click="handleActiveTab('certification')">
          {{ $t('admin.training.trainingList.popup.title.certification') }}
        </v-tab>

        <v-tab href="#ticket" @click="handleActiveTab('ticket')">
          {{ $t('admin.training.trainingList.popup.tab.ticket') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="overflow-custom mt-2">
        <v-tab-item class="training" value="training">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="trainingForm.name"
                :label="$t('admin.training.trainingList.popup.tab.name')"
                :persistent-placeholder="true"
                :rules="nameRules"
                hide-details="auto"
                placeholder="-"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="5" sm="6">
              <v-img
                :src="trainingForm.coverImage"
                class="img-container my-2"
                height="140"
                width="300"
              >
                <p v-if="!trainingForm.coverImage">
                  {{ $t('event.edit.noCover') }}
                </p>
              </v-img>

              <div v-if="canUpdatePartial" class="cover__actions d-flex justify-space-between">
                <div style="width: 46%">
                  <v-btn
                    class=""
                    color="primary"
                    width="100%"
                    @click="openPictureDialog('coverImage')"
                  >
                    {{ $t('globals.upload') }}
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </div>
                <div style="width: 46%">
                  <v-btn color="error" width="100%" @click="resetPicture()">
                    {{ $t('globals.reset') }}
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="trainer">
          <former-items
            :can-delete="canDelete"
            :can-update="canUpdate"
            :contentCategory="contentCategory"
            :contentItems="contentItems"
            :event="event"
            :itemsAreLoading="itemsAreLoading"
            @addUser="onAddTrainer"
            @edit-item="editContentItem"
            @delete-item="confirmDeleteContentItem"
          />
        </v-tab-item>

        <v-tab-item value="certification">
          <v-row>
            <v-col cols="12" sm="12">
              <v-checkbox
                v-model="trainingForm.certification.enabled"
                :label="$t('admin.training.certification.enable')"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                v-model="trainingForm.certification.integrationType"
                :disabled="!trainingForm.certification.enabled"
                :items="certificationModuleType"
                :label="$t('admin.training.certification.type')"
                item-text="label"
                item-value="id"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="trainingForm.certification.apiKey"
                :disabled="!trainingForm.certification.enabled"
                :label="$t('admin.training.certification.apiKey')"
                :persistent-placeholder="true"
                hide-details="auto"
                placeholder="-"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-if="trainingForm.certification.integrationType === 'certifier'"
                v-model="trainingForm.certification.certifierGroupId"
                :disabled="!trainingForm.certification.enabled"
                :label="$t('admin.training.certification.certifierGroupId')"
                :persistent-placeholder="true"
                hide-details="auto"
                placeholder="-"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-if="trainingForm.certification.integrationType === 'certifier'"
                v-model="trainingForm.certification.certifierCustomAttributeCourseName"
                :disabled="!trainingForm.certification.enabled"
                :label="$t('admin.training.certification.certifierCustomAttributeCourseName')"
                :persistent-placeholder="true"
                hide-details="auto"
                placeholder="-"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-label>{{ $t('admin.training.certification.customField.title') }}</v-label>
            </v-col>
          </v-row>
          <v-row
            v-for="(field, idx) in trainingForm.certification.customFields"
            :key="`custom-field-${idx}`"
            class="d-flex"
            data-test-id="row-content"
          >
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="field.name"
                :placeholder="$t('admin.training.certification.customField.name')"
                disabled
                readonly
              />
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                v-model="field.customField"
                :placeholder="$t('admin.training.certification.customField.customField')"
                disabled
                readonly
              />
            </v-col>

            <v-col cols="12" sm="1">
              <v-btn color="primary" data-test-id="delete" icon @click="removeCustomFieldLine(idx)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="d-flex" data-test-id="row-add">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="customField.name"
                :placeholder="$t('admin.training.certification.customField.value')"
              />
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                v-model="customField.customField"
                :placeholder="$t('admin.training.certification.customField.customField')"
              />
            </v-col>

            <v-col cols="12" sm="1">
              <v-btn color="secondary" data-test-id="add" icon @click="addCustomFieldLine()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-label>{{ $t('admin.training.certification.customUserField.title') }}</v-label>
            </v-col>
          </v-row>
          <v-row
            v-for="(field, idx) in trainingForm.certification.customUserFields"
            :key="`custom-user-field-${idx}`"
            class="d-flex"
            data-test-id="row-content"
          >
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="field.name"
                :placeholder="$t('admin.training.certification.customField.name')"
                disabled
                readonly
              />
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                v-model="field.customField"
                :placeholder="$t('admin.training.certification.customField.customField')"
                disabled
                readonly
              />
            </v-col>

            <v-col cols="12" sm="1">
              <v-btn
                color="primary"
                data-test-id="delete"
                icon
                @click="removeCustomUserFieldLine(idx)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="d-flex" data-test-id="row-add">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="customUserField.name"
                :placeholder="$t('admin.training.certification.customField.name')"
              />
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                v-model="customUserField.customField"
                :placeholder="$t('admin.training.certification.customField.customField')"
              />
            </v-col>

            <v-col cols="12" sm="1">
              <v-btn color="secondary" data-test-id="add" icon @click="addCustomUserFieldLine()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="ticket">
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                :items="tickets"
                item-value="id"
                item-text="name"
                v-model="trainingForm.ticketIds"
                :label="$t('admin.training.trainingList.popup.form.linkedTickets')"
                multiple
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-card-actions class="actions">
          <v-btn color="primary" data-test-id="cancel" outlined @click="goBackToList">
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn
            :disabled="!trainingForm.name"
            :loading="categoryIsSaving"
            color="primary"
            data-test-id="add"
            @click="save"
          >
            {{ isEditing ? $t('globals.save') : $t('contentLibraries.add.add') }}
          </v-btn>
        </v-card-actions>
      </v-tabs-items>
    </v-form>

    <upload-pictures
      :dialog="pictureDialog"
      :height="pictureHeight"
      :is-multiple="false"
      :width="pictureWidth"
      @close="closePictureDialog"
      @reset="resetPicture"
      @save="uploadPicture"
    />
    <dialog-confirmation
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :visible="dialogDeleteTrainer"
      content="Are you sure you wish to delete this trainer ?"
      @cancel="closeDialogConfirmDeleteTrainer"
      @confirm="confirmationDialogConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Partner } from '@/models/event/partner.model';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';

import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import FormerItems from '@/components/admin/trainings/training-list/former-item/FormerItems.vue';

import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  CLEAR_PARTNER,
  GET_PARTNER,
  PARTNER_MODULE,
  SAVE_PARTNER,
} from '@/stores/umanize-admin/actions/partners/partners.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADD_TRAINING,
  CLEAR_TRAINING,
  GET_TRAINING,
  SET_TRAINING_TRAINER_LIST_ID,
  TRAINING_ADMIN_MODULE,
  UPDATE_TRAINING,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import { AdminTraining } from '@/models';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import { TrainingCertification } from '@/models/training/admin/traning-certification.model';
import {
  ADMIN_TICKET_MODULE,
  GET_ADMIN_TICKETS,
} from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';

export default {
  name: 'EditTrainingListAdmin',
  components: {
    FormerItems,
    UploadPictures,
    DialogConfirmation,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    dataItem: {
      type: Object,
    },
  },
  data: () => ({
    tab: null,
    dialog: false,
    dialogDeleteTrainer: false,
    linkedTickets: [],
    dialogType: null,
    partnerForm: new Partner(),
    trainingForm: new AdminTraining(),
    partnerType: 'event',
    pictureDialog: false,
    pictureWidth: 800,
    isValid: false,
    pictureHeight: 450,
    partnerTypes: {
      event: [
        {
          id: 'other',
          label: 'Only on Lobby and Schedule',
        },
        {
          id: 'regular',
          label: 'Regular',
        },
        {
          id: 'platinium',
          label: 'Platinium',
        },
      ],
      conference: [
        {
          id: 'principal',
          label: 'Principal (under the event logo)',
        },
        {
          id: 'regular',
          label: 'Bottom of the page',
        },
      ],
    },
    nameRule: VALIDATORS.PARTNERS.NAME,
    typeRule: VALIDATORS.PARTNERS.TYPE,
    priorityRule: VALIDATORS.PARTNERS.PRIORITY,
    logosRule: VALIDATORS.PARTNERS.LOGOS,
    valid: false,
    pictureDialogHeight: 0,
    nameRules: VALIDATORS.REQUIRED.NAME,
    contentItemsToDelete: null,
    showConfirmation: false,
    pictureDialogWidth: 0,
    pictureDialogType: null,
    categoryIsSaving: false,
    pictureDialogConfig: {
      logoMobile: {
        height: 200,
        width: 250,
      },
      logoWeb: {
        height: 0,
        width: 0,
      },
    },
    contentItems: [],
    itemsAreLoading: false,
    contentCategory: {
      id: '11ebcac7-2e1b-cf6a-afb4-423dc357f0cd',
      eventId: '11eaf923-1e23-fcd3-b54f-ceeff581bace',
      name: 'Catégories',
      coverImage: 'https://umanize.azureedge.net/stg/11ebcac7-93c1-ad81-b633-96e8eca5f8b5',
    },
    certification: {
      enable: false,
    },
    customField: {
      name: null,
      customField: null,
    },
    customUserField: {
      name: null,
      customField: null,
    },
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    ...mapGetters(ADMIN_TICKET_MODULE, ['tickets']),
    isEditing() {
      return true;
    },
    canDelete() {
      return true;
    },
    canUpdate() {
      return true;
    },
    certificationModuleType: () => [{ id: 'certifier', label: 'Certifier.io' }],
  },
  methods: {
    ...mapActions(PARTNER_MODULE, [GET_PARTNER, CLEAR_PARTNER, SAVE_PARTNER]),
    ...mapActions(ADMIN_TICKET_MODULE, [GET_ADMIN_TICKETS]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    ...mapActions(TRAINING_ADMIN_MODULE, [
      ADD_TRAINING,
      UPDATE_TRAINING,
      GET_TRAINING,
      CLEAR_TRAINING,
      SET_TRAINING_TRAINER_LIST_ID,
    ]),
    initFormFromPartner() {
      this.trainingForm = {
        certification: new TrainingCertification(),
        eventId: this.eventId,
        ...this.dataItem,
      };
      this.contentItems = [...this.trainingForm.trainers];
    },
    handleActiveTab(tab) {
      this.$router.replace({ hash: `#${tab}` });
    },
    goBackToList() {
      this.$emit('closeDialog');
    },
    async confirmationDialogConfirm() {
      this.contentItems = this.contentItems.filter(
        (ele) => ele.id !== this.contentItemsToDelete.id,
      );
      this.contentItemsToDelete = null;
      this.dialogDeleteTrainer = false;
    },
    confirmDeleteContentItem(item) {
      this.contentItemsToDelete = item;
      this.dialogDeleteTrainer = true;
    },
    closeDialogConfirmDeleteTrainer() {
      this.dialogDeleteTrainer = false;
    },
    onAddTrainer(user) {
      this.contentItems = [user, ...this.contentItems];
    },
    editContentItem(item) {
      const { eventId, contentLibraryId } = this.$route.params;
      this.$router.push({
        name: 'EditContentItemAdmin',
        params: {
          eventId,
          contentLibraryId,
          contentItemId: item.id,
        },
      });
    },
    confirmationDialogCancel() {
      this.contentItemsToDelete = null;
      this.showConfirmation = false;
    },
    openPictureDialog(type) {
      this.dialogType = type;
      this.pictureDialog = true;
    },
    closePictureDialog() {
      this.dialogType = null;
      this.pictureDialog = false;
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      this.trainingForm.coverImage = this.file.url;
      this.closePictureDialog();
    },
    resetPicture() {
      this.trainingForm.coverImage = null;
    },
    canUpdatePartial() {
      return (
        this.canUpdate ||
        PermissionsUtil.isAuthorized(
          ['permission.contentLibraries.canUpdatePartial'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    async save() {
      this.trainingForm.trainerIds = this.contentItems.map((ele) => ele.id);

      if (this.dataItem?.id) {
        await this[UPDATE_TRAINING]({
          eventId: this.eventId,
          training: {
            ...this.trainingForm,
            deletedTicketIds: this.linkedTickets.filter(
              (id) => !this.trainingForm?.ticketIds?.includes(id),
            ),
          },
        });
      } else {
        await this[ADD_TRAINING]({
          eventId: this.eventId,
          training: { ...this.trainingForm, description: 'string' },
        });
      }
      this.goBackToList();
      this.closeDialog();
    },
    openDialog(type) {
      this.pictureDialogHeight = this.pictureDialogConfig[type].height;
      this.pictureDialogWidth = this.pictureDialogConfig[type].width;
      this.dialogType = type;
      this.dialog = true;
    },
    closeDialog() {
      this.dialogType = null;
      this.dialog = false;
    },
    async uploadLogo(picture) {
      await this[UPLOAD_FILE](picture[0]);
      this.partnerForm[this.dialogType] = this.file.url;
      this.closeDialog();
    },
    addCustomFieldLine() {
      if (!!this.customField.name && !!this.customField.customField) {
        if (!this.trainingForm.certification.customFields) {
          this.trainingForm.certification.customFields = [];
        }

        this.trainingForm.certification.customFields.push({
          name: this.customField.name,
          customField: this.customField.customField,
        });
        this.customField.name = null;
        this.customField.customField = null;
        this.emitCustomFieldsChanges();
      }
    },
    removeCustomFieldLine(index) {
      this.trainingForm.certification.customFields.splice(index, 1);
      this.emitCustomFieldsChanges();
    },
    emitCustomFieldsChanges() {
      this.$emit(
        'on-change',
        this.trainingForm.certification.customFields
          .map((line) => ({ name: line?.name?.trim(), customField: line?.customField?.trim() }))
          .filter((a) => !!a),
      );
    },

    addCustomUserFieldLine() {
      if (!!this.customUserField.name && !!this.customUserField.customField) {
        if (!this.trainingForm.certification.customUserFields) {
          this.trainingForm.certification.customUserFields = [];
        }

        this.trainingForm.certification.customUserFields.push({
          name: this.customUserField.name,
          customField: this.customUserField.customField,
        });
        this.customUserField.name = null;
        this.customUserField.customField = null;
        this.emitCustomUserFieldsChanges();
      }
    },
    removeCustomUserFieldLine(index) {
      this.trainingForm.certification.customUserFields.splice(index, 1);
      this.emitCustomUserFieldsChanges();
    },
    emitCustomUserFieldsChanges() {
      this.$emit(
        'on-change',
        this.trainingForm.certification.customUserFields
          .map((line) => ({ name: line?.name?.trim(), customField: line?.customField?.trim() }))
          .filter((a) => !!a),
      );
    },
  },
  async mounted() {
    await this[GET_ADMIN_TICKETS](this.eventId);
    if (this.dataItem?.id) {
      await this[GET_TRAINING]({
        eventId: this.eventId,
        trainingId: this.dataItem.id,
      });
      this.initFormFromPartner();
      this.linkedTickets = this.trainingForm.ticketIds;
    } else {
      this.partnerForm = {
        ...new Partner(),
        eventId: this.eventId,
      };
    }
  },
  async beforeDestroy() {
    await this[CLEAR_TRAINING]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../../styles/core/variables';
@import '../../../../../../styles/core/mixins';

@include admin-layout();

.img-container {
  height: 150px;
  width: 150px;
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}

.overflow-custom {
  overflow: unset;
}
</style>
