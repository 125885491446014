var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-list-table"},[_c('div',{staticClass:"training-list-table__actions"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"actions__create mr-2",attrs:{"disabled":!_vm.canAdd,"color":"primary","depressed":"","large":""},on:{"click":function($event){return _vm.addTraining()}}},[_vm._v(" "+_vm._s(_vm.$t('admin.training.trainingList.table.button.addTraining'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),(_vm.searchBar)?_c('v-text-field',{staticClass:"actions__search",attrs:{"label":_vm.$t('globals.search'),"dense":"","hide-details":"","outlined":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-btn',{staticClass:"users__import",attrs:{"color":"primary","dense":"","depressed":"","outlined":""},on:{"click":function($event){_vm.dialog.customizeBackground = true}}},[_vm._v(" "+_vm._s(_vm.$t('admin.training.trainingList.popup.title.customizeBackground'))+" ")])],1),_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":_vm.backgroundColor,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"headerProps":{ sortIcon: null },"headers":_vm.headers,"items":_vm.allTrainingData,"loading":_vm.trainingStateAreLoading,"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult'),"search":_vm.search,"className":"img-first"},scopedSlots:_vm._u([{key:"item.coverImage",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"container-img clickable",on:{"click":function($event){return _vm.onItemClicked(item)}}},[(item.coverImage)?_c('img',{staticClass:"container-img__img",attrs:{"src":item.coverImage}}):_c('p',{staticClass:"container-img__text"},[_vm._v("No cover image")])])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.onItemClicked(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.trainers",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.onItemClicked(item)}}},[_vm._v(" "+_vm._s(item.trainers.length)+" ")])]}},{key:"item.numOfUser",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.onItemClicked(item)}}},[_vm._v(" "+_vm._s(item.numOfUser)+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"can-delete":_vm.canDelete,"can-edit":_vm.canEdit,"has-delete":true,"user":_vm.currentLoggedInUser},on:{"edit-item":function($event){return _vm.editTraining(item)},"delete-item":function($event){return _vm.openDeleteTrainingDialog(item)}}})]}}],null,true)}),_c('dialog-confirmation',{attrs:{"title":_vm.$t('admin.partners.delete.deleteConfirmationDialog.title'),"visible":_vm.dialog.delete,"content":"Do you want to delete this training ?"},on:{"cancel":function($event){return _vm.closeDialog('delete')},"confirm":_vm.deleteTraining}}),(_vm.dialog.edit)?_c('v-dialog',{attrs:{"width":"800"},on:{"click:outside":_vm.closeDialog,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog($event)}},model:{value:(_vm.dialog.edit),callback:function ($$v) {_vm.$set(_vm.dialog, "edit", $$v)},expression:"dialog.edit"}},[_c('v-card',[_c('v-card-text',[_c('EditTrainingListAdmin',{attrs:{"dataItem":_vm.currentTraining,"eventId":_vm.eventId},on:{"closeDialog":_vm.closeDialog}})],1)],1)],1):_vm._e(),(_vm.dialog.customizeBackground)?_c('v-dialog',{attrs:{"width":"800"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog.customizeBackground),callback:function ($$v) {_vm.$set(_vm.dialog, "customizeBackground", $$v)},expression:"dialog.customizeBackground"}},[_c('v-card',[_c('v-card-text',[_c('CustomizeBackground',{attrs:{"eventId":_vm.eventId}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }