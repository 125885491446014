import { render, staticRenderFns } from "./FormerItems.vue?vue&type=template&id=75073a61&scoped=true&"
import script from "./FormerItems.vue?vue&type=script&lang=js&"
export * from "./FormerItems.vue?vue&type=script&lang=js&"
import style0 from "./FormerItems.vue?vue&type=style&index=0&id=75073a61&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75073a61",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VContainer,VDialog,VIcon})
