<template>
  <v-container class="customize-bg">
    <div class="mt-2">
      <div class="customize-bg__title">
        {{ $t(`admin.training.trainingList.popup.title.customizeBackground`) }}
      </div>

      <v-col cols="12" sm="7" lg="7" class="px-0">
        <div class="d-flex align-center">
          <v-img class="img-container my-2" height="140" width="300" :src="partnerForm.coverImage">
            <p v-if="!partnerForm.coverImage">
              {{ $t('event.edit.noCover') }}
            </p>
          </v-img>
          <div class="ml-2">
            <p class="black--text">1920, 1080px</p>
            <p class="black--text">max 2mb</p>
          </div>
        </div>

        <div v-if="canUpdatePartial" class="cover__actions d-flex">
          <v-btn class="mr-3" color="primary" @click="openPictureDialog('coverImage')">
            {{ $t('globals.upload') }}
            <v-icon>mdi-upload</v-icon>
          </v-btn>

          <v-btn color="error" @click="removePicture()">
            {{ $t('globals.reset') }}
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-card-actions class="actions">
        <v-btn data-test-id="cancel" color="primary" outlined @click="goBackToList">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn data-test-id="add" :loading="categoryIsSaving" color="primary" @click="save">
          {{ isEditing ? $t('globals.add') : $t('contentLibraries.add.add') }}
        </v-btn>
      </v-card-actions>
    </div>

    <upload-pictures
      :dialog="pictureDialog"
      :is-multiple="false"
      :height="pictureHeight"
      :width="pictureWidth"
      @save="uploadPicture"
      @close="closePictureDialog"
    />
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import { Partner } from '@/models/event/partner.model';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';

import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  CLEAR_PARTNER,
  GET_PARTNER,
  PARTNER_MODULE,
  SAVE_PARTNER,
} from '@/stores/umanize-admin/actions/partners/partners.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import set from 'lodash.set';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';

export default {
  name: 'CustomizeBackground',
  components: {
    UploadPictures,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    partnerId: {
      type: String,
    },
  },
  data: () => ({
    tab: null,
    itemsTab: ['Training', 'Former'],
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    dialog: false,
    dialogType: null,
    defaultPartner: new Partner(),
    partnerForm: new Partner(),
    partnerType: 'event',
    pictureDialog: false,
    pictureWidth: 800,
    isValid: false,
    pictureHeight: 450,
    partnerTypes: {
      event: [
        {
          id: 'other',
          label: 'Only on Lobby and Schedule',
        },
        {
          id: 'regular',
          label: 'Regular',
        },
        {
          id: 'platinium',
          label: 'Platinium',
        },
      ],
      conference: [
        {
          id: 'principal',
          label: 'Principal (under the event logo)',
        },
        {
          id: 'regular',
          label: 'Bottom of the page',
        },
      ],
    },
    nameRule: VALIDATORS.PARTNERS.NAME,
    typeRule: VALIDATORS.PARTNERS.TYPE,
    priorityRule: VALIDATORS.PARTNERS.PRIORITY,
    logosRule: VALIDATORS.PARTNERS.LOGOS,
    valid: false,
    pictureDialogHeight: 0,
    nameRules: VALIDATORS.REQUIRED.NAME,
    contentItemsToDelete: null,
    showConfirmation: false,
    pictureDialogWidth: 0,
    pictureDialogType: null,
    categoryIsSaving: false,
    pictureDialogConfig: {
      logoMobile: {
        height: 200,
        width: 250,
      },
      logoWeb: {
        height: 0,
        width: 0,
      },
    },
    contentItems: [
      {
        id: 'f9d80b62-3751-11ec-9bef-671536ce6799',
        eventId: '11eaf923-1e23-fcd3-b54f-ceeff581bace',
        categoryId: '11ebcac7-2e1b-cf6a-afb4-423dc357f0cd',
        name: 'an autre super long nom pour voir comment ca réagie avec les autres pour le plaisir',
        description: 'a',
        coverImage:
          'https://umanize.azureedge.net/stg/11ec3750-790c-6763-965d-0afbff501d30/background-lego.png',
        content: [
          {
            name:
              'an autre super long nom pour voir comment ca réagie avec les autres pour le plaisir',
            type: 'video',
            url: ['https://player.vimeo.com/video/736267842'],
          },
        ],
      },
      {
        id: '11ed18b9-b2c9-ee4c-b4a0-d2a67df7f995',
        eventId: '11eaf923-1e23-fcd3-b54f-ceeff581bace',
        categoryId: '11ebcac7-2e1b-cf6a-afb4-423dc357f0cd',
        name: 'cfdsfg',
        description: 'zdxbfcng',
        coverImage: 'https://umanize.azureedge.net/stg/11ed18ba-78a0-3616-b061-5a4a6af77b4d/blob',
        content: [
          { name: 'cfdsfg', type: 'video', url: ['https://player.vimeo.com/video/695917362'] },
        ],
      },
    ],
    itemsAreLoading: false,
    contentCategory: {
      id: '11ebcac7-2e1b-cf6a-afb4-423dc357f0cd',
      eventId: '11eaf923-1e23-fcd3-b54f-ceeff581bace',
      name: 'Catégories',
      coverImage: 'https://umanize.azureedge.net/stg/11ebcac7-93c1-ad81-b633-96e8eca5f8b5',
    },
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    isEditing() {
      return false;
    },
    getPartnerTypes() {
      return this.partnerTypes[this.$route.meta.partnerType || 'event'];
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.contentLibraries.canDelete'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(PARTNER_MODULE, [GET_PARTNER, CLEAR_PARTNER, SAVE_PARTNER]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initFormFromPartner() {
      this.partnerForm = {
        ...this.defaultPartner,
        ...cloneDeep(this.partner),
        eventId: this.eventId,
      };
    },
    handleActiveTab(tab) {
      this.$router.replace({ hash: `#${tab}` });
    },
    goBackToList() {
      this.$emit('closeDialog');
    },
    removePicture() {
      this.$emit('reset');
    },
    async confirmationDialogConfirm() {
      await this.deleteContentItem(this.contentItemsToDelete);
      this.contentItemsToDelete = null;
      this.showConfirmation = false;
    },
    confirmDeleteContentItem(item) {
      this.contentItemsToDelete = item;
      this.showConfirmation = true;
    },
    editContentItem(item) {
      const { eventId, contentLibraryId } = this.$route.params;
      this.$router.push({
        name: 'EditContentItemAdmin',
        params: {
          eventId,
          contentLibraryId,
          contentItemId: item.id,
        },
      });
    },
    confirmationDialogCancel() {
      this.contentItemsToDelete = null;
      this.showConfirmation = false;
    },
    openPictureDialog(type) {
      this.dialogType = type;
      this.pictureDialog = true;
    },
    closePictureDialog() {
      this.dialogType = null;
      this.pictureDialog = false;
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      set(this.contentLibraryForm, this.dialogType, this.file.url);
      this.closePictureDialog();
    },
    canUpdatePartial() {
      return (
        this.canUpdate ||
        PermissionsUtil.isAuthorized(
          ['permission.contentLibraries.canUpdatePartial'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    async save() {
      await this[SAVE_PARTNER]({
        partner: this.partnerForm,
        conferenceId: this.$route.params.conferenceId,
        discussionGroupId: this.$route.params.discussionGroupId,
      });
      this.goBackToList();
    },
    openDialog(type) {
      this.pictureDialogHeight = this.pictureDialogConfig[type].height;
      this.pictureDialogWidth = this.pictureDialogConfig[type].width;
      this.dialogType = type;
      this.dialog = true;
    },
    closeDialog() {
      this.dialogType = null;
      this.dialog = false;
    },
    async uploadLogo(picture) {
      await this[UPLOAD_FILE](picture[0]);
      this.partnerForm[this.dialogType] = this.file.url;
      this.closeDialog();
    },
  },
  async mounted() {
    if (this.partnerId) {
      await this[GET_PARTNER]({
        eventId: this.eventId,
        partnerId: this.partnerId,
        conferenceId: this.$route.params.conferenceId,
        discussionGroupId: this.$route.params.discussionGroupId,
      });
      this.initFormFromPartner();
    } else {
      this.partnerForm = {
        ...new Partner(),
        eventId: this.eventId,
      };
    }
  },
  async beforeDestroy() {
    await this[CLEAR_PARTNER]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../../styles/core/variables';
@import '../../../../../../styles/core/mixins';

@include admin-layout();
.customize-bg {
  &__title {
    font-weight: bold;
    color: black;
    font-size: $large-font-size;
  }
  .img-container {
    height: 150px;
    width: 150px;
    border: 1px solid var(--v-gray-base);
    p {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
    }
  }
}
</style>
