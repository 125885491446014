<template>
  <div class="content-items">
    <div class="content-items__actions mt-4">
      <v-btn
        data-test-id="add"
        class="actions__create"
        large
        depressed
        color="primary"
        @click="dialogUserList = true"
      >
        {{ $t('trainings.linkATrainerAccountToTheTraining') }}
      </v-btn>
    </div>

    <base-list
      class="mt-4"
      className="img-first"
      :headers="headers"
      :search="search"
      :items="contentItems"
      :loading="itemsAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
      :showFooter="false"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <div class="container-img">
          <img class="container-img__img" :src="item.avatar" alt="content-img" v-if="item.avatar" />
          <p class="container-img__placeholder" v-else>
            {{ $t('admin.users.noAvatar') }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <p class="truncate ml-2">{{ item.name }}</p>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn icon>
            <v-icon @click="deleteContentItem(item)" title="globals.edit">mdi-link-off </v-icon>
          </v-btn>
        </div>
      </template>
    </base-list>
    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialogUserList"
      width="auto"
      id="dialog-user-list"
    >
      <v-container id="users" class="white" style="margin-top: 50px">
        <div class="d-flex justify-space-between">
          <h2 class="user-list__title">List trainer</h2>
          <v-icon title="close" color="red" style="cursor: pointer" @click="dialogUserList = false"
            >mdi-close
          </v-icon>
        </div>
        <div class="user-list__actions">
          <AppSearch class="actions__search" @change="onSearchChange" />
        </div>

        <base-list
          class="mt-4"
          className="img-first"
          backgroundColor="var(--v-adminBackground-base)"
          :headerProps="{ sortIcon: null }"
          :headers="headersUserList"
          :search="search"
          :items="eventUsers"
          :itemPerPage="5"
          :footerProps="{
            itemsPerPageText: $t('globals.datatable.itemPerPage'),
            itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
            pageText: $t('globals.datatable.of'),
          }"
          :noDataText="$t('globals.datatable.noData')"
          :noResultsText="$t('globals.datatable.noResult')"
        >
          <template v-slot:[`item.avatar`]="{ item }">
            <div class="container-img">
              <img v-if="item.avatar" :src="item.avatar" class="container-img__img" />
              <p v-else>{{ $t('admin.users.noAvatar') }}</p>
            </div>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            {{ item.email }}
          </template>

          <template v-slot:[`item.activate`]="{ item }">
            <div class="users-actions">
              <v-btn icon color="secondary">
                <v-icon
                  size="40"
                  color="primary"
                  :title="$t('admin.users.list.deactivate')"
                  v-if="item.ticket.active"
                >
                  mdi-toggle-switch
                </v-icon>

                <v-icon size="40" :title="$t('admin.users.list.activate')" v-else>
                  mdi-toggle-switch-off
                </v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="!isAdded(item)" @click="addUser(item)" title="Add user"
              >mdi-account-plus
            </v-icon>
          </template>
        </base-list>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import BaseList from '@/components/base-list/BaseList.vue';
import { mapActions, mapGetters } from 'vuex';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { ADMIN_USER_MODULE } from '@/stores/umanize-admin/actions/user/admin-user.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { USER_TICKET_MODULE } from '@/stores/agnostic/actions/user/agnostic-user.actions';
import { FETCH_EVENT_USERS } from '@/stores/agnostic/actions/event/agnostic-event.actions';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

export default {
  name: 'FormerItems',
  components: {
    BaseList,
    AppSearch,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    contentItems: {
      type: Array,
      required: true,
    },
    contentCategory: {
      type: Object,
      required: true,
    },
    itemsAreLoading: {
      type: Boolean,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: () => false,
    },
    canDelete: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    search: '',
    watch: '',
    dialogUserList: false,
  }),
  computed: {
    headers() {
      return [
        {
          value: 'avatar',
          width: 120,
        },
        {
          text: this.$t('contentItems.list.columns.name'),
          align: 'start',
          sortable: false,
          value: 'firstName',
          width: 150,
        },
        {
          text: this.$t('admin.training.trainingList.popup.tab.lastName'),
          align: 'start',
          value: 'lastName',
          sortable: false,
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
    headersUserList() {
      return [
        {
          sortable: false,
          value: 'avatar',
          width: '100px',
        },
        {
          text: this.$t('admin.users.headers.firstName'),
          align: 'start',
          value: 'firstName',
          width: '175px',
        },
        {
          text: this.$t('admin.users.headers.lastName'),
          value: 'lastName',
          width: '175px',
        },
        {
          text: this.$t('admin.users.headers.email'),
          value: 'email',
        },
        {
          text: this.$t('admin.users.headers.active'),
          value: 'activate',
          align: 'center',
          sortable: false,
          width: '75px',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '75px',
        },
      ];
    },
    ...mapGetters(ADMIN_EVENT_MODULE, ['usersAreLoading', 'eventUsers']),
    ...mapGetters(ADMIN_USER_MODULE, ['usersIsUploading']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(`${ADMIN_USER_MODULE}/${USER_TICKET_MODULE}`, {
      isSavingTicket: 'isSaving',
    }),
  },
  methods: {
    ...mapActions(ADMIN_EVENT_MODULE, [FETCH_EVENT_USERS]),
    async getUsers() {
      const { eventId } = this.$route.params;
      await this[FETCH_EVENT_USERS]({ id: eventId });
    },
    toggleStatusConfirmation(params) {
      this.$emit('deleteUser', params);
    },
    onSearchChange(search) {
      this.search = search;
    },
    editContentItem(item) {
      this.$emit('edit-item', item);
    },
    deleteContentItem(item) {
      this.$emit('delete-item', item);
    },
    isAdded(data) {
      return this.contentItems.find((ele) => ele.id === data.id);
    },
    addUser(user) {
      this.$emit('addUser', user);
      return user;
    },
  },
  async mounted() {
    await this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../styles/core/variables';
@import '../../../../../styles/core/mixins';

@include admin-layout();

.content-items {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.truncate {
  @include ellipsis();
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 100px;
    height: 70px;
  }

  &__placeholder {
    max-width: 100px;
    text-align: center;
    margin: 0;
  }
}

@include breakpoint(medium) {
  .container-img {
    width: 100px;
    height: 100%;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    &__img {
      border-radius: 10px 0 0 10px;
    }
  }
}

#users {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.male {
  color: #2b97d1;
}

.female {
  color: #d42d65;
}

.other {
  color: grey;
}

.user-list {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.v-card__text {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 10px 10px 0;
  }
}

.btn-icon {
  margin-left: 5px;
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    font-size: $x-small-font-size;
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}

::v-deep .v-data-table__mobile-row:last-child {
  .v-data-table__mobile-row__header {
    display: none;
  }

  .v-data-table__mobile-row__cell {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    .users-actions {
      align-items: center;
      justify-content: center;
    }
  }
}

@include breakpoint(medium) {
  .container-img {
    width: 100px;
    height: 65px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    img {
      object-fit: cover;
      border-radius: 10px 0 0 10px;
    }
  }
}
</style>
