<template>
  <div class="training-list-table">
    <div class="training-list-table__actions">
      <div class="d-flex align-center">
        <v-btn
          :disabled="!canAdd"
          class="actions__create mr-2"
          color="primary"
          depressed
          large
          @click="addTraining()"
        >
          {{ $t('admin.training.trainingList.table.button.addTraining') }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>

        <v-text-field
          v-if="searchBar"
          v-model="search"
          :label="$t('globals.search')"
          class="actions__search"
          dense
          hide-details
          outlined
          prepend-inner-icon="mdi-magnify"
        />
      </div>
      <v-btn
        class="users__import"
        color="primary"
        dense
        depressed
        outlined
        @click="dialog.customizeBackground = true"
      >
        {{ $t('admin.training.trainingList.popup.title.customizeBackground') }}
      </v-btn>
    </div>

    <base-list
      :backgroundColor="backgroundColor"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :headerProps="{ sortIcon: null }"
      :headers="headers"
      :items="allTrainingData"
      :loading="trainingStateAreLoading"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
      :search="search"
      class="mt-4"
      className="img-first"
    >
      <template v-slot:[`item.coverImage`]="{ item }">
        <div class="container-img clickable" @click="onItemClicked(item)">
          <img v-if="item.coverImage" :src="item.coverImage" class="container-img__img" />
          <p v-else class="container-img__text">No cover image</p>
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div class="clickable" @click="onItemClicked(item)">
          {{ item.name }}
        </div>
      </template>

      <template v-slot:[`item.trainers`]="{ item }">
        <div class="clickable" @click="onItemClicked(item)">
          {{ item.trainers.length }}
        </div>
      </template>

      <template v-slot:[`item.numOfUser`]="{ item }">
        <div class="clickable" @click="onItemClicked(item)">
          {{ item.numOfUser }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :can-delete="canDelete"
          :can-edit="canEdit"
          :has-delete="true"
          :user="currentLoggedInUser"
          @edit-item="editTraining(item)"
          @delete-item="openDeleteTrainingDialog(item)"
        />
      </template>
    </base-list>

    <dialog-confirmation
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :visible="dialog.delete"
      content="Do you want to delete this training ?"
      @cancel="closeDialog('delete')"
      @confirm="deleteTraining"
    />

    <v-dialog
      v-if="dialog.edit"
      v-model="dialog.edit"
      width="800"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-text>
          <EditTrainingListAdmin
            :dataItem="currentTraining"
            :eventId="eventId"
            @closeDialog="closeDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="dialog.customizeBackground"
      v-model="dialog.customizeBackground"
      width="800"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-text>
          <CustomizeBackground :eventId="eventId" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';

import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import {
  CLEAR_ALL_TRAINING,
  DELETE_TRAINING,
  GET_ALL_TRAININGS_ADMIN,
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import EditTrainingListAdmin from '@/views/admin/trainings/training-list/training-list-table/dialog/EditTrainingListAdmin.vue';
import CustomizeBackground from '@/views/admin/trainings/training-list/training-list-table/dialog/CustomizeBackground.vue';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'TrainingListTable',
  components: {
    EditTrainingListAdmin,
    AdminTableActions,
    DialogConfirmation,
    BaseList,
    CustomizeBackground,
  },
  props: ['searchBar', 'canEdit', 'canAdd', 'canDelete', 'backgroundColor'],
  data: () => ({
    currentTraining: null,
    search: '',
    modalTitle: 'edit',
    dialog: {
      edit: false,
      delete: false,
      customizeBackground: false,
    },
    headers: [
      {
        value: 'coverImage',
        width: 120,
      },
      {
        text: i18n.t('admin.training.trainingList.table.headers.name'),
        value: 'name',
      },
      {
        text: 'Number of trainers',
        value: 'trainers',
        width: '100px',
        align: 'center',
      },
      {
        text: i18n.t('admin.training.trainingList.table.headers.numberOfUsers'),
        value: 'numOfUser',
        align: 'center',
        width: '100px',
      },
      {
        value: 'actions',
        width: '100px',
      },
    ],
    trainingItems: [
      {
        id: 'training-id-1',
        name: 'Formation SEO',
        numOfUser: 2,
        former: 'platinium',
        coverImage: 'https://znews-photo.zingcdn.me/w960/Uploaded/spuojka/2022_09_21/ket_xe_9.JPG',
      },
      {
        id: 'training-id-2',
        name: 'Formation Admin',
        numOfUser: 2,
        former: 'platinium',
        coverImage: 'https://znews-photo.zingcdn.me/w960/Uploaded/spuojka/2022_09_21/ket_xe_9.JPG',
      },
      {
        id: 'training-id-3',
        name: 'Test partenaire',
        numOfUser: 2,
        former: 'platinium',
        coverImage: 'https://znews-photo.zingcdn.me/w960/Uploaded/spuojka/2022_09_21/ket_xe_9.JPG',
      },
    ],
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    ...mapGetters(TRAINING_ADMIN_MODULE, [
      'allTrainingState',
      'trainingStateAreLoading',
      'allTrainingData',
      'trainingData',
    ]),
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    ...mapActions(TRAINING_ADMIN_MODULE, [
      GET_ALL_TRAININGS_ADMIN,
      DELETE_TRAINING,
      CLEAR_ALL_TRAINING,
      GET_TRAINING,
    ]),
    onItemClicked(item) {
      this.$router.push({
        name: 'TrainingFormationSEO',
        params: {
          eventId: this.eventId,
          idTraining: item.id,
        },
      });
    },
    async loadTrainings() {
      await this[GET_ALL_TRAININGS_ADMIN]({ eventId: this.eventId });
    },
    addTraining() {
      this.modalTitle = 'create';
      this.dialog.edit = true;
    },
    editTraining(item) {
      this[GET_TRAINING]({
        eventId: this.eventId,
        trainingId: item.id,
      });
      this.modalTitle = 'edit';
      this.currentTraining = { ...item };
      this.dialog.edit = true;
    },
    closeDialog(item) {
      this.currentTraining = null;
      if (item) {
        this.dialog[item] = false;
      } else {
        this.dialog.edit = false;
      }
      this.loadTrainings();
    },
    openDeleteTrainingDialog(item) {
      this.currentTraining = { ...item };
      this.dialog.delete = true;
    },
    async deleteTraining() {
      await this[DELETE_TRAINING]({
        eventId: this.eventId,
        trainingId: this.currentTraining.id,
      });
      this.closeDialog('delete');
    },
  },
  async mounted() {
    await this.loadTrainings();
  },
  beforeDestroy() {
    this[CLEAR_ALL_TRAINING]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../styles/core/variables';

@include admin-layout;

.training-list-table {
  .clickable {
    cursor: pointer;
  }

  .container-img {
    display: flex;

    &__img {
      width: 150px;
      height: 100%;
    }

    &__text {
      text-align: center;
      margin: auto;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  @include breakpoint(medium) {
    .container-img {
      width: 100px;
      height: 57px;
      border-radius: 10px 0 0 10px;
      border-right: 1px solid var(--v-gray-base);

      &__img {
        border-radius: 10px 0 0 10px;
      }
    }
  }
}

.test {
  border-top-right-radius: 10px;
}
</style>
